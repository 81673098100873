import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { getPlanningAlertApplicationsData } from '../services/api-endpoints';
import { frontClientUrl } from '../services/constant';

export const PADataRequestPage = () => {
    useEffect(() => {

        if( !(frontClientUrl().includes('dev') || frontClientUrl().includes('local')) ) {
            navigate('/404')
            return () => {};
        }
    })

    function handleDownload(body){
        
        const converter = require('json-2-csv')

        converter.json2csv(body, (err, csv) => {
            if (err) {
              throw err
            }
          
            // print CSV string
            var FileSaver = require('file-saver');
            var blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
            FileSaver.saveAs(blob, "planning-alerts-data.csv");
          })
      };

    function downloadDataFile(){

        getPlanningAlertApplicationsData().then(response => {
            const completeData = response?.data?.data

            handleDownload(completeData)
        })
        
        // return fetch(`/.netlify/functions/get-planning-application-data`, {
        //     method: 'get',
        //     headers: {'Content-Type': 'application/json'},
        //     responseType: 'blob'
        // })
        //     .then(result => result.json())
        //     .then(res => {
        //         handleDownload(res)
        //       });
    }

    return(
        <Layout>
            <Seo title="Data request"/>
            <div className="overflow-hidden lg:relative justify-center">
                <div className='py-12 px-12'>
                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                        Clicking following button will download all the applications from https://www.planningalerts.org.au/ for postcodes - 3977, 3802, 3805, 3980, 3806
                    </p>
                    <button onClick={() => downloadDataFile()} className="font-medium mx-auto text-base text-center text-white bg-green-600 rounded-xl px-6 py-4">
                        Download CSV
                    </button>
                </div>
            </div>
        </Layout>
    )
}

export default PADataRequestPage;